import React, { useMemo, useState } from 'react';
import type { NextPage } from 'next';
import { Login } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  userLoggedIn,
  requestUserLogin,
  LoginError,
} from '#/redux/actions/Auth/Auth';
import { login } from '#/api/auth';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { ILoginformValues } from '#/Utils/types';
import { AppState } from '#/redux/reducers/rootReducer';
import { clientApi } from '../../services';

const LoginPage: NextPage = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const loginState = useSelector((state: AppState) => state.auth);
  const [formValues, setFormValues] = useState<ILoginformValues>({
    email: '',
    password: '',
  });
  const router = useRouter();
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(true);
  const isLoading = useMemo(
    () => !!loginState?.isLoading || loginState.token,
    [loginState],
  );

  const handleInputChange = (e: any): void => {
    let validEmail = true;
    if (e.target.name === 'email') {
      validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(e.target?.value);
      setEmailIsValid(validEmail);
    }
    const initialFormValues: any = { ...formValues };
    initialFormValues[e.target.name] = e.target.value;

    setFormValues(initialFormValues);
    setFormIsValid(validEmail && initialFormValues.password);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    try {
      dispatch(requestUserLogin());
      const loginInfo = await login(formValues);
      dispatch(clientApi.util.invalidateTags(['TAG_AUTH', 'TAG_SESSION']));

      dispatch(userLoggedIn(loginInfo));
      enqueueSnackbar('Logged in successfully.', { variant: 'success' });

      await router.push(
        router.query.redirect && !Array.isArray(router.query.redirect)
          ? router.query.redirect
          : '/',
      );
    } catch (e: any) {
      let errorMessage = 'Something went wrong. Please try again!';
      if (e.response.status == 403) {
        errorMessage = 'Invalid username/password.';
      }
      dispatch(LoginError(errorMessage));
    }
  };

  return (
    <Login
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
      emailIsValid={emailIsValid}
      formValues={formValues}
      formIsValid={formIsValid}
      isLoading={isLoading}
    />
  );
};

export default LoginPage;
